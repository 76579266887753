import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  OnyxUserSetting,
  OnyxUserSettingDto,
  OnyxUserSettings,
} from '@onyx/angular';
import { chain } from 'lodash';
import { Duration } from 'luxon';
import { forkJoin, map, Observable, of, Subject, switchMap, tap } from 'rxjs';
import { UserSettingsScope } from '../enums/user-settings-scope';
import { ApiService } from './api.service';
import { CacheService } from './cache.service';

@Injectable({
  providedIn: 'root',
})
export class UserSettingsService extends ApiService {
  private readonly TTL = Duration.fromObject({ day: 1 });

  private _reload$ = new Subject<void>();
  public get reload$() {
    return this._reload$.asObservable();
  }

  constructor(
    protected override http: HttpClient,
    private cacheService: CacheService,
  ) {
    super(http);
  }

  public getSettings(scope: UserSettingsScope): Observable<OnyxUserSettings> {
    const cache = this.cacheService.get<OnyxUserSettings>(scope, false);
    if (cache) return of(cache);

    return forkJoin([
      this.get<OnyxUserSetting[]>(`/settings/${scope}`),
      this.get<{ default: string }>(`/settings/${scope}/default`),
    ]).pipe(
      map(
        ([settings, { default: selected }]): OnyxUserSettings => ({
          settings,
          selected,
        }),
      ),
      tap((settings) => this.cacheService.set(scope, settings, this.TTL)),
    );
  }

  public addSetting(
    scope: UserSettingsScope,
    setting: OnyxUserSettingDto,
  ): Observable<OnyxUserSetting> {
    return this.post<OnyxUserSetting>(`/settings/${scope}`, setting).pipe(
      switchMap((createdSetting) =>
        this.getSettings(scope).pipe(
          map((settings) => ({ settings, createdSetting })),
        ),
      ),
      map(({ settings, createdSetting }) => ({
        settings: {
          ...settings,
          settings: chain(settings.settings)
            .push(createdSetting)
            .uniqBy('uuid')
            .orderBy('name')
            .value(),
        },
        createdSetting,
      })),
      tap(({ settings }) => {
        this.cacheService.set(scope, settings, this.TTL);
        this._reload$.next();
      }),
      map(({ createdSetting }) => createdSetting),
    );
  }

  public editSetting(
    scope: UserSettingsScope,
    uuid: string,
    setting: OnyxUserSettingDto,
  ): Observable<OnyxUserSetting> {
    return this.put<OnyxUserSetting>(
      `/settings/${scope}/${uuid}`,
      setting,
    ).pipe(
      switchMap((updatedSetting) =>
        this.getSettings(scope).pipe(
          map((settings) => ({ settings, updatedSetting })),
        ),
      ),
      map(({ settings, updatedSetting }) => ({
        settings: {
          ...settings,
          settings: chain(settings.settings)
            .map((s) => (s.uuid === uuid ? updatedSetting : s))
            .orderBy('name')
            .value(),
        },
        updatedSetting,
      })),
      tap(({ settings }) => {
        this.cacheService.set(scope, settings, this.TTL);
        this._reload$.next();
      }),
      map(({ updatedSetting }) => updatedSetting),
    );
  }

  public deleteSetting(
    scope: UserSettingsScope,
    uuid: string,
  ): Observable<void> {
    return this.delete<void>(`/settings/${scope}/${uuid}`).pipe(
      switchMap(() => this.getSettings(scope)),
      map((settings) => ({
        ...settings,
        settings: settings.settings.filter((setting) => setting.uuid !== uuid),
      })),
      tap((settings) => {
        this.cacheService.set(scope, settings, this.TTL);
        this._reload$.next();
      }),
      map(() => undefined),
    );
  }

  public selectSetting(
    scope: UserSettingsScope,
    uuid: string | null,
  ): Observable<void> {
    return this.put<void>(`/settings/${scope}/default`, { default: uuid }).pipe(
      switchMap(() => this.getSettings(scope)),
      map((settings) => ({
        ...settings,
        selected: uuid,
      })),
      tap((settings) => {
        this.cacheService.set(scope, settings, this.TTL);
        this._reload$.next();
      }),
      map(() => undefined),
    );
  }
}
